<template>
    <div class="limiter">
        <div class="container-login100" v-if="!loadingPreAdmissao">
            <div class="wrap-login100" v-if="rangeDatasPermitido()">
                <span class="login100-form-title"> Olá! Para prosseguir, nos informe suas preferências para o seu agendamento</span>
                <span class="login50-form-title"
                    >Candidato: <b>{{ formLocal.funcionario.name.toUpperCase() }}</b></span
                >
                <span class="login50-form-title">
                    Etapa: <b>{{ formLocal.descricaoEtapa }}</b>
                </span>

                <div class="login50-form-title">
                    <span class="login50-form-title"><b>Procedimentos a realizar:</b></span>
                    <span class="card login50-form-title" v-for="procedimento of formLocal.procedimentos" v-bind:key="procedimento.procedimento.id">
                        <b>{{ procedimento.procedimento.nome }}: </b> {{ procedimento.procedimento.recomendacao }}
                    </span>
                </div>
                <Divider />
                <div class="login100-form validate-form">
                    <div class="wrap-input100 validate-input">
                        <span class="form-title">Cidade para agendamento:</span>
                        <Dropdown
                            class="input100"
                            v-model="formLocal.cidade"
                            :options="cidades"
                            optionLabel="cidade"
                            optionValue="id"
                            :filter="true"
                            @filter="buscarCidades"
                            filterPlaceholder="Digite para buscar a Cidade"
                        />
                        <small class="p-error" v-if="submitted && !formLocal.cidade">Cidade é obrigatório.</small>
                    </div>

                    <div class="wrap-input100 validate-input">
                        <span class="form-title">Range de data sugestão:</span><br />
                        <InlineMessage class="form-title-message" severity="success"
                            >Range de data disponibilizado pelo recrutador: Entre:
                            <b
                                >{{ $filters.formatDateOnly(formLocal.dataSugestaoInicial) }} e
                                {{ $filters.formatDateOnly(formLocal.dataSugestaoFinal) }}
                            </b></InlineMessage
                        >
                    </div>
                    <div>
                        <div class="validate-input sm:col-12 lg:col-6">
                            <span class="form-title">Data inicial:</span>
                            <Calendar
                                class="input100Calendar"
                                id="rangeDatas"
                                v-model="formLocal.dataSugestaoInicialFuncionario"
                                :minDate="intervaloMinDate"
                                :maxDate="intervaloMaxDate"
                                :showIcon="true"
                                placeholder="Data inicial"
                                autocomplete="off"
                                manualInput="false"
                                :disabledDays="[0, 6]"
                            />
                            <small class="p-error" v-if="submitted && !verificaDataInicial">Data Inicial é obrigatório.</small>
                        </div>

                        <div class="validate-input sm:col-12 lg:col-6">
                            <span class="form-title">Data Final:</span>
                            <Calendar
                                class="input100Calendar"
                                id="rangeDatas"
                                v-model="formLocal.dataSugestaoFinalFuncionario"
                                :minDate="intervaloMinDate"
                                :maxDate="intervaloMaxDate"
                                :showIcon="true"
                                placeholder="Data final"
                                autocomplete="off"
                                manualInput="false"
                                :disabledDays="[0, 6]"
                            />
                            <small class="p-error" v-if="submitted && !verificaDataFinal">Data Final é obrigatório.</small>
                        </div>
                    </div>

                    <div class="wrap-input100 validate-input">
                        <span class="form-title">Período:</span>
                        <Dropdown
                            class="input100"
                            id="periodo"
                            v-model="formLocal.periodo"
                            optionValue="value"
                            :options="periodo"
                            optionLabel="label"
                            placeholder="Selecione..."
                        ></Dropdown>
                        <small class="p-error" v-if="submitted && !formLocal.periodo">Período é obrigatório.</small>
                    </div>
                    <div v-if="formLocal.cidade" class="wrap-input100 validate-input">
                        <span class="form-title">Clínica:</span>
                        <Dropdown
                            class="input100"
                            v-model="formLocal.prestador"
                            :options="prestadores"
                            optionLabel="name"
                            :filter="false"
                            filterPlaceholder="Digite para buscar a Cidade"
                        />
                        <small class="p-error" v-if="submitted && !formLocal.prestador">Prestador é obrigatório.</small>
                    </div>
                    <div v-if="formLocal.cidade" class="wrap-input100 validate-input">
                        <div v-if="formLocal?.prestador">
                            <div class="mt-2">
                                <div class="card">
                                    <b>Dados do Parceiro: </b>
                                    <div v-if="formLocal?.prestador?.street" class="field"><b>Rua: </b> {{ formLocal?.prestador?.street }}</div>
                                    <div v-if="formLocal?.prestador?.addressComplement" class="field">
                                        <b>Complemento:</b> {{ formLocal?.prestador?.addressComplement }}
                                    </div>
                                    <div v-if="formLocal?.prestador?.addressReference" class="field">
                                        <b>Referência:</b> {{ formLocal?.prestador?.addressReference }}
                                    </div>
                                    <div v-if="formLocal?.prestador?.zipCode" class="field"><b>CEP:</b> {{ formLocal?.prestador?.zipCode }}</div>
                                    <div v-if="formLocal?.prestador?.phone" class="field">
                                        <b>Contato Parceiro:</b> {{ formLocal?.prestador?.phone }}
                                    </div>
                                </div>
                            </div>
                            <div class="container-login100-form-btn">
                                <Button class="login50-form-btn" icon="pi pi-clock" label="Horário de Atendimento" iconPos="right" />
                            </div>

                            <div v-if="formLocal.prestador" class="mt-2">
                                <div class="field">
                                    <div class="field"><b>Horário de atendimento:</b></div>
                                    <DataTable :value="prestadorHorario" responsiveLayout="scroll">
                                        <Column field="dia" header="Dia"></Column>
                                        <Column field="manha" header="Manhã"></Column>
                                        <Column field="tarde" header="Tarde"></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap-input100 validate-input">
                        <span class="form-title">Observação:</span>
                        <Textarea
                            :autoResize="true"
                            class="input100 textAreaObs"
                            style="padding-top: 5px"
                            id="observacao"
                            v-model="formLocal.observacao"
                        />
                    </div>
                    <InlineMessage class="form-title-message" severity="warn"
                        >Essa etapa é apenas uma solicitação. Aguarde as próximas etapas para concluir seu agendamento!</InlineMessage
                    >
                    <div class="container-login100-form-btn">
                        <Button class="login100-form-btn" aria-label="Prosseguir" :loading="buttonLoading" @click="atualizarPreAdmissao">Prosseguir</Button>
                    </div>
                </div>
            </div>
            <div class="wrap-login100" v-if="!rangeDatasPermitido()">
                <h1>Não é possível realizar o agendamento do seu exame!</h1>
                <br />
                <p>
                    Infelizmente, o período de datas disponibilizado pelo contratante já foi expirado (Entre dia
                    {{ $filters.formatDateOnly(formLocal.dataSugestaoInicial) }} até {{ $filters.formatDateOnly(formLocal.dataSugestaoFinal) }}).
                </p>
                <p>Por favor entre em contato com o responsável para disponibilizar uma nova data.</p>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import { getClientBase } from '@/services/http';
import EnumStatusPreAdmissao from '../../../../enums/EnumStatusPreAdmissao';
import { showError } from '../../../../utils/Toast';
export default {
    props: {
        responsePreAdmissao: {
            type: Object,
            required: true
        }
    },
    emits: ['onCarregarPreAdmissao', 'onAtualizarPreAdmissao', 'onError'],

    data() {
        return {
            rangeInvalido: false,
            prestadores: [],
            dataInicialFuncionario: null,
            dataFinalFuncionario: null,
            prestadorHorario: [],
            verificaData: false,
            verificaDataInicial: false,
            verificaDataFinal: false,
            loadingPreAdmissao: false,
            intervaloMinDate: null,
            intervaloMaxDate: null,
            submitted: false,
            buttonLoading: false,
            cidades: [],
            search: '',
            periodo: [
                { label: 'Manhã', value: 1 },
                { label: 'Tarde', value: 2 },
                { label: 'Ambos', value: 3 }
            ]
        };
    },
    model: {
        prop: 'responsePreAdmissao',
        event: 'formchange',
        dataNascimento: null
    },
    computed: {
        formLocal: {
            get: function () {
                return this.responsePreAdmissao || {};
            },
            set: function (value) {
                this.$emit('formchange', value);
            }
        }
    },
    watch: {
        'formLocal.cidade'() {
            this.buscaPrestadores();
        },
        'formLocal.prestador'() {
            this.formatarHorario();
        }
    },
    mounted() {
        this.intervaloMinDate = moment(this.formLocal.dataSugestaoInicial).toDate();
        this.intervaloMaxDate = moment(this.formLocal.dataSugestaoFinal).toDate();
    },
    methods: {
        async buscarCidades(event) {
            const dto = {
                cpf: this.formLocal.cpf,
                token: this.formLocal.token,
                cidade: event.value
            };
            const responseCidades = await getClientBase().post(`agendamento-pre-admissao/find-cidades`, dto);
            this.cidades = responseCidades.data.items;
        },
        rangeDatasPermitido() {
            return new Date(this.formLocal.dataSugestaoFinal) >= new Date();
        },

        async buscaPrestadores() {
            const dto = {
                cpf: this.formLocal.cpf,
                token: this.formLocal.token,
                customerId: this.formLocal.funcionario.customerId,
                cidadeId: this.formLocal.cidade
            };
            const responsePrestadores = await getClientBase().post(`agendamento-pre-admissao/find-prestadores`, dto);
            this.prestadores = responsePrestadores.data;
        },
        async formatarHorario() {
            this.prestadorHorario = [];
            this.formLocal?.prestador?.openingHours.forEach((horario) => {
                let dtoHorario = [];
                dtoHorario.dia = horario.weekDay;
                dtoHorario.id = horario.id;
                if (horario.serviceTypeAm == 'ORDEM_CHEGADA') {
                    horario.serviceTypeAm = 'Ordem de Chegada';
                }
                if (horario.serviceTypePm == 'ORDEM_CHEGADA') {
                    horario.serviceTypePm = 'Ordem de Chegada';
                }
                if (horario.serviceTypeAm == 'HORARIO_MARCADO') {
                    horario.serviceTypeAm = 'Horário Marcado';
                }
                if (horario.serviceTypePm == 'HORARIO_MARCADO') {
                    horario.serviceTypePm = 'Horário Marcado';
                }
                if (!horario.atendimentoVespertino) {
                    dtoHorario.tarde = 'Sem atendimento';
                }
                if (!horario.atendimentoMatutino) {
                    dtoHorario.manha = 'Sem atendimento';
                }
                if (horario.atendimentoMatutino) {
                    dtoHorario.manha = horario.startHourAm + ' as ' + horario.endHourAm + ' por: ' + horario.serviceTypeAm;
                }
                if (horario.atendimentoVespertino) {
                    dtoHorario.tarde = horario.startHourPm + ' as ' + horario.endHourPm + ' por: ' + horario.serviceTypePm;
                }
                this.prestadorHorario.push(dtoHorario);
            });
        },
        async atualizarPreAdmissao() {
            this.buttonLoading = true;
            this.loadingPreAdmissao = true;

            if(this.validarPodeAtualizar()){
                this.submitted = true;
                this.etapa = EnumStatusPreAdmissao.EM_AGENDAMENTO_CRIAR;
                const dto = {
                dataSugestaoInicialFuncionario: this.formLocal.dataSugestaoInicialFuncionario,
                dataSugestaoFinalFuncionario: this.formLocal.dataSugestaoFinalFuncionario,
                idPrestador: this.formLocal.prestador?.id?.idPrestador,
                idCidade: this.formLocal.cidade,
                observacao: this.formLocal.observacao,
                periodo: this.formLocal.periodo,
                etapa: this.etapa
                };
                await this.$emit('onAtualizarPreAdmissao', dto);
            }
        this.submitted = false;
        this.loadingPreAdmissao = false;
        this.buttonLoading = false;

        },
        validarPodeAtualizar() {
            if (
                this.formLocal.dataSugestaoInicialFuncionario &&
                this.formLocal.dataSugestaoFinalFuncionario &&
                this.verificaDataInicialMenorQueAFinal() &&
                this.verificaDataFinalMaiorQueAInicial() &&
                this.formLocal.prestador?.id?.idPrestador &&
                this.formLocal.cidade &&
                this.formLocal.periodo
            ) {
                return true;
            }
            showError(this.$toast,'erro', 'Existem campos não preenchidos, verifique!');
            return false;
        },

        verificaDataInicialMenorQueAFinal() {
            if (this.formLocal.dataSugestaoInicialFuncionario <= this.formLocal.dataSugestaoFinalFuncionario) {
                return true;
            }
            showError(this.$toast,'erro', 'A data inicial precisa ser menor ou igual a data final');
            return false;
        },

        verificaDataFinalMaiorQueAInicial() {
            if (this.formLocal.dataSugestaoFinalFuncionario >= this.formLocal.dataSugestaoInicialFuncionario) {
                return true;
            }
            showError(this.$toast,'erro', 'A data final precisa ser maior ou igual a data inicial');
            return false;
        },

        exibirHorarioAtendimento() {
            this.horarioAtendimento = !this.horarioAtendimento;
        }
    }
};
</script>
<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('../../../../../public/fonts/poppins/Poppins-Regular.ttf');
}
@font-face {
    font-family: Poppins-Bold;
    src: url('../../../../../public/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../../../../../public/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: Montserrat-Bold;
    src: url('../../../../../public/fonts/montserrat/Montserrat-Bold.ttf');
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: Poppins-Regular, sans-serif;
}

a {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #57b846;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

p {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}

input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}
input:focus:-moz-placeholder {
    color: transparent;
}
input:focus::-moz-placeholder {
    color: transparent;
}
input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}
textarea:focus:-moz-placeholder {
    color: transparent;
}
textarea:focus::-moz-placeholder {
    color: transparent;
}
textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #999999;
}
input:-moz-placeholder {
    color: #999999;
}
input::-moz-placeholder {
    color: #999999;
}
input:-ms-input-placeholder {
    color: #999999;
}

textarea::-webkit-input-placeholder {
    color: #999999;
}
textarea:-moz-placeholder {
    color: #999999;
}
textarea::-moz-placeholder {
    color: #999999;
}
textarea:-ms-input-placeholder {
    color: #999999;
}

button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}

.txt1 {
    font-family: Poppins-Regular;
    font-size: 13px;
    line-height: 1.5;
    color: #999999;
}

.txt2 {
    font-family: Poppins-Regular;
    font-size: 13px;
    line-height: 1.5;
    color: #666666;
}

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #e2256e;
    background: -webkit-linear-gradient(-135deg, #7fdff7, #7fdff7);
    background: -o-linear-gradient(-135deg, #c850c0, #7fdff7);
    background: -moz-linear-gradient(-135deg, #c850c0, #7fdff7);
    background: linear-gradient(-135deg, #f89ed0, #e2256e);
}

.wrap-login100 {
    width: 960px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 80px 130px 33px 95px;
}

.login100-pic {
    width: 316px;
}

.login100-pic img {
    max-width: 100%;
}

.login100-form-title {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: #333333;
    line-height: 1.2;
    text-align: center;

    width: 100%;
    display: block;
    padding-bottom: 20px;
}

.login50-form-title {
    font-size: 15px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    display: block;
    padding-bottom: 15px;
}

.form-title {
    font-size: 15px;
    color: #333333;
    line-height: 1.2;
    width: 100%;
    display: block;
    padding-bottom: 5px;
}
.form-title-message {
    font-family: Poppins-Medium;
    font-size: 10px;
    line-height: 1.5;
    width: 100%;
    height: 40px;
    padding: 0 2px 0 2px;
}
.wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
}
.textAreaObs {
    min-height: 100px;
}
.input100 {
    font-family: Poppins-Medium;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;

    width: 100%;
    background: #e6e6e6;
    height: 40px;
    border-radius: 25px;
    padding: 0 30px 0 20px;
}

.input100Calendar {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #666666;
    width: 100%;
    height: 40px;
}

.input50 {
    font-family: Poppins-Medium;
    font-size: 10px;
    line-height: 1.5;
    color: #666666;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 20px;
    border-radius: 25px;
    padding: 0 10px 0 68px;
}

.focus-input100 {
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px;
    color: rgba(87, 184, 70, 0.8);
}

.input100:focus + .focus-input100 {
    -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
    animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 70px 25px;
        opacity: 0;
    }
}

@keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 70px 25px;
        opacity: 0;
    }
}

.symbol-input100 {
    font-size: 15px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 35px;
    pointer-events: none;
    color: #666666;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
    color: #57b846;
    padding-left: 28px;
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
}

.card {
    padding: 20px;
}

.login100-form-btn {
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #f7066a;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login50-form-btn {
    font-family: Montserrat-Bold;
    font-size: 9px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    height: 30px;
    border-radius: 10px;
    background: #116377;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background: #333333;
}

@media (max-width: 992px) {
    .wrap-login100 {
        padding: 177px 90px 33px 85px;
    }

    .login100-pic {
        width: 35%;
    }
}

@media (max-width: 768px) {
    .wrap-login100 {
        padding: 100px 80px 33px 80px;
    }

    .login100-pic {
        display: none;
    }
}

@media (max-width: 576px) {
    .wrap-login100 {
        padding: 100px 15px 33px 15px;
    }
}

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: white;
    border: 1px solid #c80000;
    border-radius: 13px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px;
    pointer-events: none;

    font-family: Poppins-Medium;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;

    visibility: hidden;
    opacity: 0;

    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: '\f06a';
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 13px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}
</style>