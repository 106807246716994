<template>
    <div class="">
        <Toast />
        <div class="loading" v-if="loadingPreAdmissao">
            <ProgressBar mode="indeterminate" style="height: 0.3em" />
            <span class="login100-form-title"> AGUARDE, CARREGANDO INFORMAÇÕES... </span>
        </div>

        <PreAdmissaoIniciar
            v-if="!etapa && !loadingPreAdmissao"
            @onCarregarPreAdmissao="carregarPreAdmissao"
            @onAtualizarPreAdmissao="atualizarPreAdmissao"
        />
        <PreAdmissaoConferenciaDados
            :responsePreAdmissao="responsePreAdmissao.data"
            v-if="etapa == 'CONFERENCIA_DADOS' && !loadingPreAdmissao"
            ref="PreAdmissaoIniciado"
            @onCarregarPreAdmissao="carregarPreAdmissao"
            @onAtualizarPreAdmissao="atualizarPreAdmissao"
        />
        <PreAdmissaoCriarAgendamento
            :responsePreAdmissao="responsePreAdmissao.data"
            v-if="(etapa == 'CRIAR_AGENDAMENTO' || etapa == 'DADOS_CONFERIDOS' || etapa == 'EM_AGENDAMENTO_CRIAR') && !loadingPreAdmissao"
            ref="PreAdmissaoCriarAgendamento"
            @onCarregarPreAdmissao="carregarPreAdmissao"
            @onAtualizarPreAdmissao="atualizarPreAdmissao"
        />
        <PreAdmissaoEmAgendamento
            :responsePreAdmissao="responsePreAdmissao.data"
            v-if="etapa == 'AGENDAMENTO_CRIADO' && !loadingPreAdmissao"
            ref="PreAdmissaoEmAgendamento"
            @onCarregarPreAdmissao="carregarPreAdmissao"
            @onAtualizarPreAdmissao="atualizarPreAdmissao"
        />
        <PreAdmissaoConcluido
            :responsePreAdmissao="responsePreAdmissao.data"
            v-if="etapa == 'CONFIRMAR_COMPARECIMENTO' || (etapa == 'CONCLUIDO' && !loadingPreAdmissao)"
            ref="PreAdmissaoConcluido"
            @onCarregarPreAdmissao="carregarPreAdmissao"
            @onAtualizarPreAdmissao="atualizarPreAdmissao"
        />
    </div>
</template>

<script>
import { getClientBase } from '@/services/http';
import PreAdmissaoIniciar from './PreAdmissaoIniciar.vue';
import PreAdmissaoConferenciaDados from './PreAdmissaoConferenciaDados.vue';
import PreAdmissaoCriarAgendamento from './PreAdmissaoCriarAgendamento.vue';
import PreAdmissaoEmAgendamento from './PreAdmissaoEmAgendamento.vue';
import PreAdmissaoConcluido from './PreAdmissaoConcluido.vue';
import { showWarning } from '../../../../utils/Toast';
export default {
    components: {
        PreAdmissaoIniciar,
        PreAdmissaoConferenciaDados,
        PreAdmissaoCriarAgendamento,
        PreAdmissaoEmAgendamento,
        PreAdmissaoConcluido
    },
    data() {
        return {
            aceitar: false,
            cpf: null,
            token: null,
            etapa: null,
            etapaCriado: false,
            etapaIniciado: false,
            etapaAgendado: false,
            etapaConcluida: false,
            agendamento: [],
            responsePreAdmissao: {},
            loadingPreAdmissao: false
        };
    },
    async created() {},
    async mounted() {},
    methods: {
        async carregarPreAdmissao(dto) {
            this.loadingPreAdmissao = true;
            try {
                this.responsePreAdmissao = await getClientBase().post(`agendamento-pre-admissao/find-token-cpf`, dto.credenciais);
                this.etapa = this.responsePreAdmissao.data.etapa;
                this.descricaoEtapa = this.etapa;
                this.loadingPreAdmissao = false;
            } catch (error) {
                this.loadingPreAdmissao = false;
                const message = error?.response?.data?.message;
                showWarning(this.$toast, message);
            }
        },
        async atualizarPreAdmissao(dto) {
            try {
                this.loadingPreAdmissao = true;
                this.responsePreAdmissao = await getClientBase().patch(`agendamento-pre-admissao/${this.responsePreAdmissao.data.id}`, dto);
                this.etapa = this.responsePreAdmissao.data.etapa;
                this.descricaoEtapa = this.etapa;
                this.loadingPreAdmissao = false;
            } catch (error) {
                this.loadingPreAdmissao = false;
                const message = error?.response?.data?.message;
                showWarning(this.$toast, message);
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('../../../../../public/fonts/poppins/Poppins-Regular.ttf');
}
@font-face {
    font-family: Poppins-Bold;
    src: url('../../../../../public/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../../../../../public/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: Montserrat-Bold;
    src: url('../../../../../public/fonts/montserrat/Montserrat-Bold.ttf');
}
.loading {
    font-family: Poppins-Bold;
    font-size: 30px;
    color: #b1b1b1;
    text-align: center;
    line-height: 1.7;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #ececec;
}
</style>