<template>
    <div class="limiter">
        <div class="container-login100" v-if="!loadingPreAdmissao">
            <div class="wrap-login100">
                <span class="login100-form-title"> Admissão Digital </span>
                <span class="login50-form-title">Candidato: <b>{{ formLocal?.funcionario?.nome.toUpperCase()
                        }}</b></span>

                <span class="login50-form-title">Período: <b>{{ periodo }}</b></span>

                <span class="login50-form-title">Cidade para agendamento: <b>{{ formLocal.cidade.toUpperCase()
                        }}</b></span>

                <span class="login50-form-title">Range de data disponibilizado pelo recrutador:
                    <b>{{ $filters.formatDateOnly(formLocal.dataSugestaoInicial) }} e
                        {{ $filters.formatDateOnly(formLocal.dataSugestaoFinal) }}
                    </b></span>

                <span class="login50-form-title">Range de data disponibilizado pelo funcionário:
                    <b>{{ $filters.formatDateOnly(formLocal.dataSugestaoInicial) }} e
                        {{ $filters.formatDateOnly(formLocal.dataSugestaoFinal) }}
                    </b></span>

                <span v-if="formLocal.observacao" class="login50-form-title">Observação: <b>{{ formLocal.observacao
                        }}</b></span>

                <Divider />

                <span class="login100-form-title">
                    <InlineMessage class="form-title-message" severity="info">
                        Etapa: <b>{{ formLocal.statusAgendamento }}</b>
                    </InlineMessage>
                    <InlineMessage class="form-title-message mt-4" severity="warn">
                        <b>Sua solicitação foi direcionada a um atendente. Aguarde que você reberá instruções do seu
                            agendamento</b>
                    </InlineMessage>
                </span>

                <div v-if="formLocal.statusAgendamento == 'Em Aberto' || formLocal.statusAgendamento == 'Em Andamento'"
                    class="login50-form-title">
                    <span class="login50-form-title login100-form-btn"><b>Aguarde o contato para finalizar o
                            agendamento.</b></span>
                </div>

                <div
                    v-if="formLocal.prestador?.systemsAndTechnology?.name == 'SOC' && formLocal.statusAgendamento == 'Aguardando confirmação agenda'">
                    <Button label="Clique aqui para reservar horários" class="login100-form-btn"
                        @click="onClickMenuReservaHorarios(formLocal.agendamentoId)"
                        v-tooltip="'Clique aqui para reservar horários'" />
                    <ReservaHorariosDialog v-if="showReservaHorariosDialog" v-model:visible="showReservaHorariosDialog"
                        :agendamentoId="formLocal.agendamentoId" @onConfirm="onConfirmReservaHorarios"
                        @onClose="showReservaHorariosDialog = false" />
                </div>

                <div v-if="formLocal.statusAgendamento != 'Em Aberto' &&
            formLocal.statusAgendamento != 'Em Andamento' &&
            formLocal.statusAgendamento != 'Aguardando confirmação agenda'
            " class="login50-form-title">
                    <span v-if="formLocal.statusAgendamento == 'Aguardando Conf. Funcionário'" class="field pb-3">
                        <InlineMessage severity="warn" class="form-title p-3">
                            Para concluir o processo de agendamento, confirme através do <b>WhatsApp enviado</b>, as
                            informações do
                            agendamento.</InlineMessage>
                    </span>
                    <span v-if="formLocal.statusAgendamento == 'Agendado'">
                        <InlineMessage severity="success" class="form-title p-3">
                            <b>Parabéns!</b> Você concluiu seu agendamento. Agora basta apenas seguir as orientações
                            abaixo, e não esqueça de
                            comparecer no local informado.
                        </InlineMessage>
                    </span>
                    <span class="login50-form-title pt-3"><b>Resumo do agendamento:</b></span>
                    <span class="login50-form-title card" v-for="procedimento of formLocal.procedimentos"
                        v-bind:key="procedimento.id">
                        <span class="login50-form-title"> <b>{{ procedimento.procedimentos.name }}:</b> {{
            $filters.formatDate(procedimento.dataAgendamento) }} por {{ openHoursPrestador }}</span>
                        <span class="login50-form-title"><b>Recomendação:</b> {{ procedimento.procedimentos.recomendacao
                            }}</span>
                        <span v-for="procedimento of formLocal.procedimentos" v-bind:key="procedimento.id">
                            <div class="p-input-icon-right pb-4 mb-3">
                                <div class="field mb-3"><b>Dados do Parceiro:</b></div>
                                <div v-if="procedimento?.prestador?.name" class="field"> <b>Clinica:</b> {{
            procedimento?.prestador?.name }}</div>
                                <div v-if="procedimento?.prestador?.addressComplement" class="field">
                                    <b>Complemento:</b> {{ procedimento?.prestador?.addressComplement }}</div>
                                <div v-if="procedimento?.prestador?.street" class="field"> <b>Rua: </b> {{
            procedimento?.prestador?.street }}</div>
                                <div v-if="procedimento?.prestador?.addressReference" class="field"> <b>Referência:</b>
                                    {{ procedimento?.prestador?.addressReference }}</div>
                                <div v-if="procedimento?.prestador?.zipCode" class="field"> <b>CEP:</b> {{
                                    procedimento?.prestador?.zipCode }}</div>
                                <div v-if="procedimento?.prestador?.phone" class="field"> <b>Contato Parceiro:</b> {{
                                    procedimento?.prestador?.phone }}</div>

                            </div>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone';
import EnumStatusPreAdmissao from '../../../../enums/EnumStatusPreAdmissao';
import ReservaHorariosDialog from '../ReservaHorariosDialog.vue';
export default {
    emits: ['onCarregarPreAdmissao', 'onClose'],
    components: { ReservaHorariosDialog },
    data() {
        return {
            cpf: null,
            token: null,
            descricaoEtapa: null,
            etapa: null,
            agendamento: {},
            showComentarios: false,
            loadingPreAdmissao: false,
            showReservaHorariosDialog: false,
            periodo: [
                { label: 'MANHÃ', value: 1 },
                { label: 'TARDE', value: 2 },
                { label: 'AMBOS', value: 3 }
            ],
            openHoursPrestador: null
        };
    },
    props: {
        responsePreAdmissao: {
            type: Object,
            required: true
        }
    },
    model: {
        prop: 'responsePreAdmissao',
        event: 'formchange',
        dataNascimento: null
    },
    computed: {
        formLocal: {
            get: function () {
                return this.responsePreAdmissao || {};
            },
            set: function (value) {
                this.$emit('formchange', value);
            }
        }
    },
    watch: {
        'formLocal.funcionario.dataNascimento'() {
            this.formatFormDatas();
        }
    },
    async created() { },
    async mounted() {
        if (this.formLocal.periodo == '1') this.periodo = 'Manha';
        if (this.formLocal.periodo == '2') this.periodo = 'Tarde';
        if (this.formLocal.periodo == '3') this.periodo = 'Ambos';

        for (let i = 0; i < this.formLocal.procedimentos.length; i++) {
            const procedimento = this.formLocal.procedimentos[i];
            const horario = procedimento.prestador.openingHours.openingHoursEncontradas;
            if (horario.serviceTypeAm == 'ORDEM_CHEGADA') {
                this.openHoursPrestador = 'Ordem de Chegada';
            }
            if (horario.serviceTypePm == 'ORDEM_CHEGADA') {
                this.openHoursPrestador = 'Ordem de Chegada';
            }
            if (horario.serviceTypeAm == 'HORARIO_MARCADO') {
                this.openHoursPrestador = 'Horário Marcado';
            }
            if (horario.serviceTypePm == 'HORARIO_MARCADO') {
                this.openHoursPrestador = 'Horário Marcado';
            }
        }

        this.formatFormDatas();
        if (this.formLocal.etapa == EnumStatusPreAdmissao.AGENDAMENTO_CRIADO) this.descricaoEtapa = 'Aguardando Confirmação';
        this.agendamento = {
            funcionario: {
                nome: this.formLocal.funcionario.nome
            },
            id: this.formLocal.agendamentoId,
            origem: this.formLocal.funcionario.nome,
            user: this.formLocal.user
        };
    },
    methods: {
        formatFormDatas() {
            this.formLocal.funcionario.dataNascimento = this.formLocal.funcionario.dataNascimento
                ? moment.tz(this.formLocal.funcionario.dataNascimento, 'YYYY-MM-DD', 'America/Sao_Paulo').toDate()
                : null;
        },
        abrirChat() {
            this.showComentarios = !this.showComentarios;
        },
        carregarPreAdmissao() {
            this.cpf = this.formLocal.cpf;
            this.token = this.formLocal.token;
            this.loadingPreAdmissao = true;
            this.submitted = true;
            if (!this.cpf || !this.token) return;
            this.cpf = this.cpf.replace(/[^a-zA-Z0-9]/g, "");
            const dto = {
                credenciais: {
                    cpf: this.cpf,
                    token: this.token
                },
            };
            this.$emit('onCarregarPreAdmissao', dto);
            this.submitted = false;
            this.loadingPreAdmissao = false;
        },
        onClickMenuReservaHorarios(record) {
            this.actionRecord = record;
            this.showReservaHorariosDialog = true;
        },
        onConfirmReservaHorarios() {
            this.showReservaHorariosDialog = false;
            this.carregarPreAdmissao();
        }
    }
};
</script>
<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('../../../../../public/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('../../../../../public/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../../../../../public/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: Montserrat-Bold;
    src: url('../../../../../public/fonts/montserrat/Montserrat-Bold.ttf');
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: Poppins-Regular, sans-serif;
}

a {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #57b846;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

p {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}

input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}

input:focus::-moz-placeholder {
    color: transparent;
}

input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}

textarea:focus:-moz-placeholder {
    color: transparent;
}

textarea:focus::-moz-placeholder {
    color: transparent;
}

textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #999999;
}

input:-moz-placeholder {
    color: #999999;
}

input::-moz-placeholder {
    color: #999999;
}

input:-ms-input-placeholder {
    color: #999999;
}

textarea::-webkit-input-placeholder {
    color: #999999;
}

textarea:-moz-placeholder {
    color: #999999;
}

textarea::-moz-placeholder {
    color: #999999;
}

textarea:-ms-input-placeholder {
    color: #999999;
}

button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}

.txt1 {
    font-family: Poppins-Regular;
    font-size: 13px;
    line-height: 1.5;
    color: #999999;
}

.txt2 {
    font-family: Poppins-Regular;
    font-size: 13px;
    line-height: 1.5;
    color: #666666;
}

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #e2256e;
    background: -webkit-linear-gradient(-135deg, #7fdff7, #7fdff7);
    background: -o-linear-gradient(-135deg, #c850c0, #7fdff7);
    background: -moz-linear-gradient(-135deg, #c850c0, #7fdff7);
    background: linear-gradient(-135deg, #f89ed0, #e2256e);
}

.wrap-login100 {
    width: 960px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 80px 130px 33px 95px;
}

.login100-pic {
    width: 316px;
}

.login100-pic img {
    max-width: 100%;
}

.login100-form-title {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: #333333;
    line-height: 1.2;
    text-align: center;

    width: 100%;
    display: block;
    padding-bottom: 20px;
}

.login50-form-title {
    font-size: 15px;
    color: #333333;
    line-height: 1.2;
    // text-align: center;
    width: 100%;
    display: block;
    padding-bottom: 15px;
}

.form-title {
    font-size: 15px;
    color: #333333;
    line-height: 1.2;
    width: 100%;
    display: block;
    padding-bottom: 5px;
}

.form-title-message {
    font-family: Poppins-Medium;
    font-size: 10px;
    line-height: 1.5;
    width: 100%;
    height: 40px;
    padding: 0 2px 0 2px;
}

.wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
}

.input100 {
    font-family: Poppins-Medium;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;

    width: 100%;
    background: #e6e6e6;
    height: 40px;
    border-radius: 25px;
    padding: 0 30px 0 20px;
}

.input100Calendar {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #666666;
    width: 100%;
    height: 40px;
}

.input50 {
    font-family: Poppins-Medium;
    font-size: 10px;
    line-height: 1.5;
    color: #666666;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 20px;
    border-radius: 25px;
    padding: 0 10px 0 68px;
}

.focus-input100 {
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px;
    color: rgba(87, 184, 70, 0.8);
}

.input100:focus+.focus-input100 {
    -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
    animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 70px 25px;
        opacity: 0;
    }
}

@keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 70px 25px;
        opacity: 0;
    }
}

.symbol-input100 {
    font-size: 15px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 35px;
    pointer-events: none;
    color: #666666;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input100:focus+.focus-input100+.symbol-input100 {
    color: #57b846;
    padding-left: 28px;
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
}

.card {
    padding: 20px;
}

.login100-form-btn {
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #f7066a;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login50-form-btn {
    font-family: Montserrat-Bold;
    font-size: 9px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    height: 30px;
    border-radius: 10px;
    background: #116377;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background: #333333;
}

@media (max-width: 992px) {
    .wrap-login100 {
        padding: 177px 90px 33px 85px;
    }

    .login100-pic {
        width: 35%;
    }
}

@media (max-width: 768px) {
    .wrap-login100 {
        padding: 100px 80px 33px 80px;
    }

    .login100-pic {
        display: none;
    }
}

@media (max-width: 576px) {
    .wrap-login100 {
        padding: 100px 15px 33px 15px;
    }
}

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: white;
    border: 1px solid #c80000;
    border-radius: 13px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px;
    pointer-events: none;

    font-family: Poppins-Medium;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;

    visibility: hidden;
    opacity: 0;

    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: '\f06a';
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 13px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}
</style>