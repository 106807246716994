<template>
    <div class="limiter">
        <div class="container-login100">
            <div class="wrap-login100">
                <span class="login100-form-title">
                    Olá! Bem vindo a Admissao digital, por gentileza confira os dados abaixo e clique em prosseguir.
                </span>
                <div class="login100-form validate-form">
                    <div class="wrap-input100 validate-input">
                        <span class="login50-form-title"><b>Nome completo</b></span>
                        <InputText
                            class="input100"
                            id="name"
                            :disabled="disabledDadosCadastrais"
                            v-model="formLocal.funcionario.name"
                            placeholder="Nome"
                        />
                    </div>
                    <div class="field">
                        <div class="wrap-input100 validate-input">
                            <span class="login50-form-title"><b>Data de Nascimento</b></span>
                            <InputMask
                                class="input100"
                                mask="99/99/9999"
                                id="dataNascimento"
                                v-model="dataFormatada"
                                placeholder="Data de Nascimento"
                                :disabled="disabledDadosCadastrais"
                            />
                            <small class="p-error" v-if="!dataFormatada && !dataNascimentoIsValid"
                                >Data de nascimento inválida.</small
                            >
                            <small class="p-error" v-if="!validarDataMenorQue100Anos"
                                >Data de nascimento inválida, verefique o ano.</small
                            >
                        </div>
                    </div>
                    <div class="wrap-input100 validate-input">
                        <Checkbox class="ml-2" v-model="ajustarDados" :binary="true" />
                        <label class="m-2" for="aceitar">Caso haja divergência cadastral, clique aqui e altere</label>
                    </div>

                    <div class="container-login100-form-btn">
                        <button class="login100-form-btn" aria-label="Prosseguir" @click="atualizarPreAdmissao">Prosseguir</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EnumStatusPreAdmissao from '../../../../enums/EnumStatusPreAdmissao';
import moment from 'moment-timezone';
export default {
    emits: ['onCarregarPreAdmissao', 'onAtualizarPreAdmissao'],
    data() {
        return {
            cpf: null,
            token: null,
            etapa: null,
            nome: null,
            dataFormatada: null,
            ajustarDados: null,
            disabledDadosCadastrais: true,
            loadingPreAdmissao: false
        };
    },
    props: {
        responsePreAdmissao: {
            type: Object,
            required: true
        }
    },
    model: {
        prop: 'responsePreAdmissao',
        event: 'formchange',
        dataNascimento: null
    },
    computed: {
        formLocal: {
            get: function () {
                return this.responsePreAdmissao || {};
            },
            set: function (value) {
                this.$emit('formchange', value);
            }
        }
    },
    watch: {
        'formLocal.funcionario.dataNascimento'() {
            this.formatFormDatas();
        },
        'formLocal.funcionario.name'() {
            this.nome = this.formLocal.funcionario.name;
        },
        ajustarDados() {
            this.disabledDadosCadastrais = !this.disabledDadosCadastrais;
        }
    },
    async created() {
        this.nome = this.formLocal.funcionario.name.toUpperCase();
    },
    async mounted() {
        this.formatFormDatas();
    },
    methods: {
        validarDatas() {
            const dataAdmissional = this.formLocal.funcionario?.admissionDate?.split('/');
            const dataNascismento = this.dataFormatada?.split('/');
            const dataNascimentoFormatada = this.onFormatDateSave(dataNascismento);
            const dataAgora = moment().toDate();
            if (moment(dataAdmissional).isAfter(dataNascimentoFormatada) || moment(dataAgora).isAfter(dataNascimentoFormatada)) {
                return true;
            }

            return false;
        },
        async validarDataMenorQue100Anos() {
            const dataAdmissional = this.formLocal.funcionario?.admissionDate?.split('/');
            const dataNascimentoFormatada = this.onFormatDateSave(this.dataFormatada?.split('/'));
            const dataAgora = await moment().toDate();
            const yearDataAgora = moment(dataAgora).add(-99, 'years').year();
            const yearDataNascimento = moment(dataNascimentoFormatada).year();
            const yearDataAdmissional = moment(dataAdmissional).year();
            if (yearDataNascimento < yearDataAgora || yearDataAdmissional < yearDataAgora) {
                return true;
            }

            return false;
        },
        formatFormDatas() {
            this.dataFormatada = this.$filters.formatDateOnly(this.formLocal.funcionario.dataNascimento);
        },

        onFormatDateSave(date) {
            return date ? moment(`${date[2]}-${date[1]}-${date[0]}`).toDate() : null;
        },

        async atualizarPreAdmissao() {
            this.loadingPreAdmissao = true;
            if (!this.disabledDadosCadastrais) {
                this.etapa = EnumStatusPreAdmissao.DADOS_CONFERIDOS_ALTERAR_DADOS;
                const validarDataMenorQue100Anos = this.validarDataMenorQue100Anos();
                if (!validarDataMenorQue100Anos) {
                    return this.$toast.add({ severity: 'info', summary: 'Data de nascimento inválida, verefique o ano.', life: 3000 });
                }

                const verificarDatas = await this.validarDatas();
                if (!verificarDatas) {
                    return this.$toast.add({ severity: 'info', summary: 'A data de admissão menor do que a de nascimento.', life: 3000 });
                }
            }
            if (this.disabledDadosCadastrais) {
                this.etapa = EnumStatusPreAdmissao.DADOS_CONFERIDOS;
            }

            const admissionDate = this.formLocal.funcionario?.admissionDate;
            const resignationDate = this.formLocal.funcionario?.resignationDate;

            const dto = {
                etapa: this.etapa,
                nome: this.nome,
                dataNascimento: this.dataFormatada,
                admissionDate: admissionDate,
                resignationDate: resignationDate
            };
            await this.$emit('onAtualizarPreAdmissao', dto);
            this.loadingPreAdmissao = false;
        }
    }
};
</script>
<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('../../../../../public/fonts/poppins/Poppins-Regular.ttf');
}
@font-face {
    font-family: Poppins-Bold;
    src: url('../../../../../public/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('../../../../../public/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
    font-family: Montserrat-Bold;
    src: url('../../../../../public/fonts/montserrat/Montserrat-Bold.ttf');
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    font-family: Poppins-Regular, sans-serif;
}

a {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
}

a:focus {
    outline: none !important;
}

a:hover {
    text-decoration: none;
    color: #57b846;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

p {
    font-family: Poppins-Regular;
    font-size: 14px;
    line-height: 1.7;
    color: #666666;
    margin: 0px;
}

ul,
li {
    margin: 0px;
    list-style-type: none;
}

input {
    outline: none;
    border: none;
}

textarea {
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    border-color: transparent !important;
}

input:focus::-webkit-input-placeholder {
    color: transparent;
}
input:focus:-moz-placeholder {
    color: transparent;
}
input:focus::-moz-placeholder {
    color: transparent;
}
input:focus:-ms-input-placeholder {
    color: transparent;
}

textarea:focus::-webkit-input-placeholder {
    color: transparent;
}
textarea:focus:-moz-placeholder {
    color: transparent;
}
textarea:focus::-moz-placeholder {
    color: transparent;
}
textarea:focus:-ms-input-placeholder {
    color: transparent;
}

input::-webkit-input-placeholder {
    color: #999999;
}
input:-moz-placeholder {
    color: #999999;
}
input::-moz-placeholder {
    color: #999999;
}
input:-ms-input-placeholder {
    color: #999999;
}

textarea::-webkit-input-placeholder {
    color: #999999;
}
textarea:-moz-placeholder {
    color: #999999;
}
textarea::-moz-placeholder {
    color: #999999;
}
textarea:-ms-input-placeholder {
    color: #999999;
}

button {
    outline: none !important;
    border: none;
    background: transparent;
}

button:hover {
    cursor: pointer;
}

iframe {
    border: none !important;
}

.txt1 {
    font-family: Poppins-Regular;
    font-size: 13px;
    line-height: 1.5;
    color: #999999;
}

.txt2 {
    font-family: Poppins-Regular;
    font-size: 13px;
    line-height: 1.5;
    color: #666666;
}

.limiter {
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: #e2256e;
    background: -webkit-linear-gradient(-135deg, #7fdff7, #7fdff7);
    background: -o-linear-gradient(-135deg, #c850c0, #7fdff7);
    background: -moz-linear-gradient(-135deg, #c850c0, #7fdff7);
    background: linear-gradient(-135deg, #f89ed0, #e2256e);
}

.wrap-login100 {
    width: 960px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 80px 130px 33px 95px;
}

.login100-pic {
    width: 316px;
}

.login100-pic img {
    max-width: 100%;
}

.login100-form-title {
    font-family: Poppins-Bold;
    font-size: 24px;
    color: #333333;
    line-height: 1.2;
    text-align: center;

    width: 100%;
    display: block;
    padding-bottom: 20px;
}

.login50-form-title {
    font-size: 15px;
    color: #333333;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    display: block;
    padding-bottom: 15px;
}

.form-title {
    font-size: 15px;
    color: #333333;
    line-height: 1.2;
    width: 100%;
    display: block;
    padding-bottom: 5px;
}
.form-title-message {
    font-family: Poppins-Medium;
    font-size: 10px;
    line-height: 1.5;
    width: 100%;
    height: 40px;
    padding: 0 2px 0 2px;
}
.wrap-input100 {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 10px;
}

.input100 {
    font-family: Poppins-Medium;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;

    width: 100%;
    background: #e6e6e6;
    height: 40px;
    border-radius: 25px;
    padding: 0 30px 0 20px;
}

.input100Calendar {
    font-family: Poppins-Medium;
    font-size: 15px;
    color: #666666;
    width: 100%;
    height: 40px;
}

.input50 {
    font-family: Poppins-Medium;
    font-size: 10px;
    line-height: 1.5;
    color: #666666;
    display: block;
    width: 100%;
    background: #e6e6e6;
    height: 20px;
    border-radius: 25px;
    padding: 0 10px 0 68px;
}

.focus-input100 {
    display: block;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px;
    color: rgba(87, 184, 70, 0.8);
}

.input100:focus + .focus-input100 {
    -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
    animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 70px 25px;
        opacity: 0;
    }
}

@keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 70px 25px;
        opacity: 0;
    }
}

.symbol-input100 {
    font-size: 15px;

    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    border-radius: 25px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-left: 35px;
    pointer-events: none;
    color: #666666;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.input100:focus + .focus-input100 + .symbol-input100 {
    color: #57b846;
    padding-left: 28px;
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 20px;
}

.card {
    padding: 20px;
}

.login100-form-btn {
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    height: 50px;
    border-radius: 25px;
    background: #f7066a;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login50-form-btn {
    font-family: Montserrat-Bold;
    font-size: 9px;
    line-height: 1.5;
    color: #fff;
    text-transform: uppercase;
    height: 30px;
    border-radius: 10px;
    background: #116377;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;

    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
}

.login100-form-btn:hover {
    background: #333333;
}

@media (max-width: 992px) {
    .wrap-login100 {
        padding: 177px 90px 33px 85px;
    }

    .login100-pic {
        width: 35%;
    }
}

@media (max-width: 768px) {
    .wrap-login100 {
        padding: 100px 80px 33px 80px;
    }

    .login100-pic {
        display: none;
    }
}

@media (max-width: 576px) {
    .wrap-login100 {
        padding: 100px 15px 33px 15px;
    }
}

.validate-input {
    position: relative;
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: white;
    border: 1px solid #c80000;
    border-radius: 13px;
    padding: 4px 25px 4px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 8px;
    pointer-events: none;

    font-family: Poppins-Medium;
    color: #c80000;
    font-size: 13px;
    line-height: 1.4;
    text-align: left;

    visibility: hidden;
    opacity: 0;

    -webkit-transition: opacity 0.4s;
    -o-transition: opacity 0.4s;
    -moz-transition: opacity 0.4s;
    transition: opacity 0.4s;
}

.alert-validate::after {
    content: '\f06a';
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 13px;
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1;
}

@media (max-width: 992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1;
    }
}
</style>