const EnumStatusPreAdmissao = {
    CONFIRMAR_COMPARECIMENTO : 'CONFIRMAR_COMPARECIMENTO',
    CONCLUIDO : 'CONCLUIDO',
    AGENDAMENTO_CRIADO : 'AGENDAMENTO_CRIADO',
    DADOS_CONFERIDOS : 'DADOS_CONFERIDOS',
    CRIAR_AGENDAMENTO : 'CRIAR_AGENDAMENTO',
    EM_AGENDAMENTO_CRIAR : 'EM_AGENDAMENTO_CRIAR',
    CRIADO : 'CRIADO',
    CONFERENCIA_DADOS : 'CONFERENCIA_DADOS',
    DADOS_CONFERIDOS_ALTERAR_DADOS : 'DADOS_CONFERIDOS_ALTERAR_DADOS'
}

export const EnumStatusPreAdmissaoLabel = new Map([
    [EnumStatusPreAdmissao.CONFIRMAR_COMPARECIMENTO, 'Confirmar comparecimento'],
    [EnumStatusPreAdmissao.CONCLUIDO, 'Concluído'],
    [EnumStatusPreAdmissao.AGENDAMENTO_CRIADO, 'Agendamento criado'],
    [EnumStatusPreAdmissao.DADOS_CONFERIDOS, 'Dados conferidos'],
    [EnumStatusPreAdmissao.CRIAR_AGENDAMENTO, 'Criar agendamento'],
    [EnumStatusPreAdmissao.EM_AGENDAMENTO_CRIAR, 'Criando Agendamento'],
    [EnumStatusPreAdmissao.CRIADO, 'Criado Pré-Admissão'],
    [EnumStatusPreAdmissao.CONFERENCIA_DADOS, 'Conferência de dados'],
    [EnumStatusPreAdmissao.DADOS_CONFERIDOS_ALTERAR_DADOS, 'Dados conferidos']
]);

export default EnumStatusPreAdmissao;
